<template>
  <div class="container-fluid">
    <div class="item infos">
      <h3>Obrigado {{ user.first_name }}!</h3>
      <span>Pedido enviado com sucesso!</span>

      <p>
       Em breve você receberá o termo aditivo para ser assinado via zapsign.
      </p>
    </div>
    <div class="item actions">

      <div class="btns" v-if="user.company.id !== 13">
        <router-link
          to="/kit/minha-personalizacao/"
          class="btn btn-info mt-1 font-15"
          style="background-color: var(--color); font-weight: 600"
        >
          MINHA PERSONALIZAÇÃO</router-link
        >
                  <!-- :href="'https://api.whatsapp.com/send?phone='+user.company_phone_area_code+user.company_phone" -->

        <!-- <a
          href="https://api.whatsapp.com/send?phone=556299957044"
          target="_blank"
          class="btn btn-info mt-1 font-15"
          style="
            border: 1px solid var(--color);
            background: transparent;
            color: var(--color);
          "
          ><i class="fab fa-whatsapp font-24 align-middle mr-2"></i>
          <span style="font-weight: 600">FALAR COM UM CONSULTOR</span>
        </a> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters.getProfile;
    },
  },
  mounted() {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  }
};
</script>
<style scoped>
.container-fluid {
  min-height: 100vh;
  padding-top: 60px !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  background: #fff;
}
.item {
  padding: 40px 100px;
  margin: 0 auto;
  width: 100%;
}
.infos {
  text-align: center;
  border-right: 1px solid #cecece46;
}
.infos h3 {
  color: rgba(0, 0, 0, 0.5);
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 12px;
}
.infos span {
  display: block;
  color: rgba(40, 190, 92, 0.7);
  font-size: 22px;
  padding-bottom: 30px;
  border-bottom: 1px solid #cecece46;
  font-weight: 500;
}
.infos p {
  margin-top: 30px;
  font-weight: 500;
  color: var(--color);
  font-size: 18px;
}

.actions p {
  color: #9e9e9a;
  font-size: 15px;
}
.actions .btns {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  max-width: 70%;
  gap: 10px;
}
.actions .btns a {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>